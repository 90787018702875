// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    apiUrl: 'https://dev-dsp.ddns.net/api',
    msalConfig: {
      auth: {
          clientId: '56131f08-dc09-4ca4-a622-2b8099546e98',
          authority: 'https://login.microsoftonline.com/72e42a61-9cee-4b78-8828-29b226163bd7/'
      }
    },
    apiConfig: {
        scopes: [`56131f08-dc09-4ca4-a622-2b8099546e98/user.read`],
        uri: 'https://dev-dsp.ddns.net/api'
    }
  };
  
  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  // import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
  