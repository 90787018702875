import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkRoleExists'
})
export class CheckRoleExistsPipe implements PipeTransform {

  transform(roles: any[], role_name?: string): any {
    return roles.some(role => role.role_id === role_name);
  }

}
