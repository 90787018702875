<div class="container">
  <table>
    <tr>
        <td>Код та назва дисципліни</td>
        <td>{{annotation?.code}} {{annotation?.discipline_name}}</td>
    </tr>
    <tr>
        <td>Рекомендується для галузі знань (спеціальності освітньої програми)</td>
        <td>{{ annotation?.knowledge_field?.name }}</td>
    </tr>
    <tr>
        <td>Кафедра (зазначати повну назву кафедри)</td>
        <td>{{ annotation?.chair?.name }}</td>
    </tr>
    <tr>
        <td>П.І.П. НПП (за можливості)</td>
        <td>{{ annotation?.employee?.last_name + ' ' + annotation.employee?.first_name + ' ' + annotation?.employee?.middle_name }}</td>
    </tr>
    <tr>
        <td>Рівень ВО</td>
        <td>{{ annotation?.education_level?.name }}</td>
    </tr>
    <tr>
        <td>Курс семестр (в якому буде викладатись)</td>
        <td> 
            Курс: 
            <ng-container *ngIf="!annotation?.min_allowed_course && !annotation?.max_allowed_course">Будь-який</ng-container>
            <ng-container *ngIf="annotation?.min_allowed_course">{{annotation?.min_allowed_course}}</ng-container>
            <ng-container *ngIf="annotation?.min_allowed_course && annotation?.max_allowed_course"> - </ng-container>
            <ng-container *ngIf="annotation?.max_allowed_course">{{annotation?.max_allowed_course}}</ng-container>
            <ng-container *ngIf="annotation?.semester_type && annotation?.semester_type !== 0">
                <br>
                Семестр:
                <ng-container [ngSwitch]="annotation?.semester_type">
                    <ng-container *ngSwitchCase="1">Непарний</ng-container>
                    <ng-container *ngSwitchCase="2">Парний</ng-container>
                </ng-container>
            </ng-container>
            
        </td>
    </tr>
    <tr>
        <td>Мова викладання</td>
        <td>{{ annotation?.language?.language }}</td>
    </tr>
    <tr>
        <td>Пререквізити (передумови вивчення дисципліни)</td>
        <td>{{ annotation?.prerequisites }}</td>
    </tr>
    <tr>
        <td>Що буде вивчатися</td>
        <td>{{ annotation?.that_will_be_studied }}</td>
    </tr>
    <tr>
        <td>Чому це цікаво/треба вивчати</td>
        <td>{{ annotation?.why_is_it_interesting }}</td>
    </tr>
    <tr>
        <td>Чого можна навчитися (результати навчання)</td>
        <td>{{ annotation?.study_results }}</td>
    </tr>
    <tr>
        <td>Як можна користуватися набутими знаннями і уміннями (компетентність)</td>
        <td>{{ annotation?.study_results }}</td>
    </tr>
    <tr>
        <td>Інформаційне забезпечення</td>
        <td>{{ annotation?.information_support }}</td>
    </tr>
    <tr>
        <td>Види навчальних занять (лекції, практичні, семінарські, лабораторні заняття тощо)</td>
        <td><ng-container *ngFor="let hour of annotation?.discipline_hours; let i = index;">{{hour?.form?.name}} ({{hour?.hours*14}} год.)<ng-container *ngIf="i !== annotation.discipline_hours.length -1">, </ng-container></ng-container></td>
    </tr>
    <tr>
        <td>Вид семестрового контролю</td>
        <td>{{ annotation?.control_type?.control_type }}</td>
    </tr>
    <tr>
        <td>Максимальна кількість здобувачів на семестр</td>
        <td>{{ annotation?.max_number_of_students }}</td>
    </tr>
  </table>
  <footer>
    <p>Декан факультету ________________ Ігор ГОМІЛКО</p>
  </footer>
</div>