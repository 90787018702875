import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root-header',
  templateUrl: './root-header.component.html',
  styleUrls: ['./root-header.component.scss']
})
export class RootHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
