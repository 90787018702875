import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  userRoles: Array<{id: number, role: string, role_description: string}> = [];
  constructor(private http: HttpClient) { }

  getUserInfo(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/users/me`)
      .pipe(
        tap(response => this.userRoles = response.roles)
      )
  }
}
