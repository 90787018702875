<div class="wrapper">
    <div class="item-box" routerLink="/app/home" [routerLinkActiveOptions]="{exact: false}" routerLinkActive="active">
        <mat-icon>home</mat-icon>
        <span class="text">Головна</span>  
    </div>
    <div class="item-box" routerLink="/app/disciplines" [routerLinkActiveOptions]="{exact: false}" routerLinkActive="active">
        <mat-icon>list</mat-icon>
        <span class="text">Дисципліни</span>  
    </div>
    <div class="item-box" routerLink="/app/admin" [routerLinkActiveOptions]="{exact: false}" routerLinkActive="active" *ngIf="(userService.userRoles | checkRoleExists: 'Admin') || (userService.userRoles | checkRoleExists: 'Supervisor')">
        <mat-icon>list</mat-icon>
        <span class="text">Адмінка</span>  
    </div>
</div>