import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RootNavComponent } from './components/root-nav/root-nav.component';
import { RootHeaderComponent } from './components/root-header/root-header.component';
import { RouterModule } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { CheckRoleExistsPipe } from './pipes/checkRoleExists.pipe';
import { MatSelectModule } from '@angular/material/select';
import { CreateButtonComponent } from './components/create-button/create-button.component';
import { OrderByFieldPipe } from './pipes/orderByField.pipe';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PdfTemplateComponent } from './components/pdf-template/pdf-template.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatExpansionModule,
    MatGridListModule,
    MatSelectModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatButtonToggleModule
  ],
  declarations: [
    RootNavComponent,
    RootHeaderComponent,
    CheckRoleExistsPipe,
    CreateButtonComponent,
    OrderByFieldPipe,
    PdfTemplateComponent
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    RootNavComponent,
    RootHeaderComponent,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatExpansionModule,
    MatGridListModule,
    CheckRoleExistsPipe,
    MatSelectModule,
    CreateButtonComponent,
    OrderByFieldPipe,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    PdfTemplateComponent,
    MatButtonToggleModule
  ]
})
export class SharedModule {}
